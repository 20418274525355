/* src/components/DebugGPS.css */

#debugGPStool {
    background-color: #1a1a1a;
    color: white;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 40px;
  }
  
  .debugGPS-header {
    margin-bottom: 20px;
  }
  
  .debugGPS-header h3 {
    font-size: 24px;
    text-align: center;
    margin: 0;
  }
  
  .debugGPS-input-group {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .debugGPS-input-field {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  
  .debugGPS-input-field label {
    margin-bottom: 5px;
  }
  
  .debugGPS-input-field input {
    background-color: #333;
    color: white;
    border: 2px solid white;
    border-radius: 5px;
    padding: 5px;
    font-size: 16px;
  }
  
  .debugGPS-speed-value {
    margin-left: 10px;
  }
  
  .debugGPS-button-group {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .debugGPS-button-group button {
    flex: 1;
    background-color: #00843D;
    color: white;
    border: 2px solid white;
    border-radius: 5px;
    padding: 10px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .debugGPS-button-group button:hover {
    background-color: #00a651;
  }
  
  .debugGPS-button-group button:disabled {
    background-color: #555;
    cursor: not-allowed;
  }
  
  .debugGPS-status-group {
    background-color: #333;
    border: 2px solid white;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 20px;
  }
  
  .debugGPS-status-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }
  
  .debugGPS-status-label {
    font-weight: bold;
  }
  
  .debugGPS-tunnel-distances {
    background-color: #333;
    border: 2px solid white;
    border-radius: 5px;
    padding: 10px;
  }
  
  .debugGPS-tunnel-distances h4 {
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 10px;
    text-align: center;
  }
  
  .debugGPS-tunnel-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: 10px;
  }
  
  .debugGPS-tunnel-item {
    background-color: #00843D;
    border: 2px solid white;
    border-radius: 5px;
    padding: 5px;
    text-align: center;
  }
  
  .debugGPS-tunnel-abbr {
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
  }
  
  .debugGPS-tunnel-distance {
    font-family: 'Seven Segment', sans-serif;
    font-size: 18px;
    color: #32CD32;
    text-shadow: 0 0 5px #00ff00;
  }
  
.debugGPS-progress-container {
    margin-bottom: 20px;
  }
  
  .debugGPS-progress-label {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .debugGPS-progress-bar {
    height: 20px;
    background-color: #333;
    border: 2px solid white;
    border-radius: 10px;
    overflow: hidden;
  }
  
  .debugGPS-progress-bar-fill {
    height: 100%;
    background-color: #00843D;
    transition: width 0.5s ease-in-out;
  }