/* src/components/BottomMenu.css */
.bottom-menu {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #004d25;
    padding: 10px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }
  