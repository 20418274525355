/* src/components/Settings.css */
.settings-dropdown {
    position: fixed;
    bottom: 84px;
    right: 10px;
    background-color: #004d25;
    border-radius: 4px;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    display: none;
    flex-direction: column;
  }
  
  .settings-dropdown.show {
    display: flex;
  }