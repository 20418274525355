/* src/components/ToggleButton.css */
.toggle-button {
  background-color: transparent;
  border: none;
  color: white;
  padding: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: background-color 0.3s ease;
}

.toggle-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.toggle-button:active {
  background-color: rgba(255, 255, 255, 0.2);
}

.toggle-button-icon {
  font-size: 24px;
  margin-bottom: 5px;
  color: white;
  transition: opacity 0.3s ease;
}

.toggle-button span {
  white-space: nowrap;
}

/* Set opacity to 0.5 when the button is off */
.toggle-button:not(.active) .toggle-button-icon {
  opacity: 0.5;
}

/* Ensure the icon color doesn't change in any state */
.toggle-button:hover .toggle-button-icon,
.toggle-button:active .toggle-button-icon,
.toggle-button:focus .toggle-button-icon,
.toggle-button.active .toggle-button-icon {
  color: white;
}

/* Ensure full opacity when the button is active */
.toggle-button.active .toggle-button-icon {
  opacity: 1;
}