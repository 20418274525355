/* src/components/CycleButton.css */
.cycle-button {
    background-color: transparent;
    border: none;
    color: white;
    padding: 10px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: background-color 0.3s ease;
  }
  
  .cycle-button:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  
  .cycle-button:active {
    background-color: rgba(255, 255, 255, 0.2);
  }
  
  .cycle-button-icon {
    font-size: 24px;
    margin-bottom: 5px;
    color: white;
    transition: opacity 0.3s ease;
  }
  
  .cycle-button-status {
    font-size: 1em;
    opacity: 1;
  }
  
  /* Style for when the button is not in its first state */
  .cycle-button[aria-pressed="true"] .cycle-button-icon {
    opacity: 1;
  }
  
  /* Style for when the button is in its first state (usually "off") */
  .cycle-button[aria-pressed="false"] .cycle-button-icon {
    opacity: 1;
  }
  
  /* Ensure the icon color doesn't change in any state */
  .cycle-button:hover .cycle-button-icon,
  .cycle-button:active .cycle-button-icon,
  .cycle-button:focus .cycle-button-icon {
    color: white;
  }