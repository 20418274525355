/* src/components/DebugTunnelFee.css */

#debugTunnelFee-tool {
  background-color: #1a1a1a;
  color: white;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 40px;
}

.debugTunnelFee-header {
  margin-bottom: 20px;
}

.debugTunnelFee-header h3 {
  font-size: 24px;
  text-align: center;
  margin: 0;
}

.debugTunnelFee-input-group {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.debugTunnelFee-input-field {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.debugTunnelFee-input-field label {
  margin-bottom: 5px;
}

.debugTunnelFee-input-field input {
  background-color: #333;
  color: white;
  border: 2px solid white;
  border-radius: 5px;
  padding: 5px;
  font-size: 16px;
}

.debugTunnelFee-acceleration-value {
  margin-left: 10px;
}

.debugTunnelFee-button-group {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.debugTunnelFee-button-group button {
  flex: 1;
  background-color: #00843D;
  color: white;
  border: 2px solid white;
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.debugTunnelFee-button-group button:hover {
  background-color: #00a651;
}

.debugTunnelFee-button-group button:disabled {
  background-color: #555;
  cursor: not-allowed;
}

.debugTunnelFee-status-group {
  background-color: #333;
  border: 2px solid white;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 20px;
}

.debugTunnelFee-status-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.debugTunnelFee-status-label {
  font-weight: bold;
}

.debugTunnelFee-fees {
  background-color: #333;
  border: 2px solid white;
  border-radius: 5px;
  padding: 10px;
}

.debugTunnelFee-fees h4 {
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 10px;
  text-align: center;
}

.debugTunnelFee-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 10px;
}

.debugTunnelFee-item {
  background-color: #00843D;
  border: 2px solid white;
  border-radius: 5px;
  padding: 5px;
  text-align: center;
}

.debugTunnelFee-abbr {
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
}

.debugTunnelFee-fee {
  font-family: 'Seven Segment', sans-serif;
  font-size: 18px;
  color: #32CD32;
  text-shadow: 0 0 5px #00ff00;
}