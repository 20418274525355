@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+HK:wght@400;700&display=swap');

.tunnel {
    margin-bottom: 40px;
    font-family: 'Noto Sans HK', sans-serif;
  }
  
  .tunnel-name {
    font-size: 24px;
    margin-bottom: 10px;
    text-align: center;
  }
  
  .tunnel-info {
    display: flex;
    gap: 10px;
    height: 80px;
  }
  
  .tunnel-icon, .toll-info {
    border: 2px solid white;
    border-radius: 5px;
    display: flex;
    align-items: stretch;
    flex: 7;
  }

  .tunnel-icon {
    background-color: #00843D;
    justify-content: center;
    align-items: center;
    padding: 2px;
    flex: 3;
  }

  .tunnel-name {
    font-size: 24px;
    margin-bottom: 10px;
    text-align: center;
  }

  .tunnel-icon svg {
    width: 100%;
    height: 100%;
  }
  .toll-info {
    flex: 7;
  }


  .fee {
    background-color: black;
    color: #32CD32;
    padding: 5px;
    font-size: 70px;
    font-weight: bold;
    flex: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: 'Seven Segment', sans-serif;
  }
  
  .fee-text {
    color: #32CD32;
  }
  
  @keyframes fadeInOut {
    0%, 100% { opacity: 1; }
    50% { opacity: 0; }
  }
  
  .fee-text.fade-animate {
    animation: fadeInOut 1s ease-in-out 3;
  }
  

  .currency {
    background-color: #00843D;
    padding: 5px;
    border-left: 2px solid white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    word-break: break-word;
    text-align: center;
    font-size: 20px;
  }
  
  .distance {
    margin-top: 10px;
    text-align: right;
    transition: all 0.3s ease;
    background-color: black;
    border-radius: 15px;
    padding: 2px 8px;
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    color: #32CD32;;
    float: right;
    font-family: 'Seven Segment', sans-serif;
  }
  .distance-value {
    transition: opacity 0.5s ease-in-out;
    
  }
  
  .distance-value.fade-out {
    opacity: 0;
  }
  
  .distance-value.fade-in {
    opacity: 1;
  }
  
  .distance-icon {
    margin-right: 8px;
    color: white;
  }