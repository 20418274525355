/* src/components/TunnelList.css */

.container {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
  }

#tunnelList {
  position: relative;
  overflow: hidden;
}

.tunnel-item-wrapper {
  position: relative;
  width: 100%;
}

/* TunnelList.css */
.tunnel-item {
  transition: transform 0.5s ease;
}